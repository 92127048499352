type CreateMetaUrlOptions = {
  baseUrl: string;
  path: string;
  lang?: string;
}

export const createMetaUrl = ( options: CreateMetaUrlOptions ) => {
  
  return options.lang ? `${options.baseUrl}/${options.lang}${options.path}` : `${options.baseUrl}${options.path}`;
}
